import {
    Wrapper,
    MapWrapper,
    MapContext,
    MapController,
    Tooltip,
    Toast,
    ToastProvider,
} from '@keypro/2nd-xp';
import {
    AppToolbar,
    AppMap,
    AppMenus,
    AppNavigationBar,
    AppFooter,
    MobileAppToolbar,
} from '@components';
import { useEffect, useState } from 'react';
import '@i18n';
import { useGetPlacementScales } from '@hooks/map';
import { LoadingPage } from '@pages';
import { useTxtConstants } from '@stores';

const MainPage = () => {
    const [controller, setController] = useState<MapController | undefined>();

    const { data: placementScales } = useGetPlacementScales();
    const {
        txtConstants,
        isLoading: loadingTxtConstants,
        fetchTxtConstants,
    } = useTxtConstants();

    useEffect(() => {
        if (placementScales) {
            setController(
                new MapController({
                    placementScales: placementScales,
                }),
            );
        }
    }, [placementScales]);

    useEffect(() => {
        if (txtConstants.length === 0) {
            fetchTxtConstants();
        }
    }, [txtConstants, fetchTxtConstants]);

    // Prevent rendering if still loading or if controller is not set
    if (controller === undefined || loadingTxtConstants) {
        return <LoadingPage />;
    }

    return (
        <ToastProvider>
            <Toast />
            <Wrapper>
                <Tooltip />
                <MapContext.Provider value={controller}>
                    <AppToolbar />
                    <MobileAppToolbar />
                    <MapWrapper>
                        <AppNavigationBar />
                        <AppMap />
                        <AppMenus />
                    </MapWrapper>
                    <AppFooter />
                </MapContext.Provider>
            </Wrapper>
        </ToastProvider>
    );
};

export default MainPage;
