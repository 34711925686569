import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useGetInfoTools } from '@hooks/map';
import {
    MapContext,
    ObjectPopup,
    ObjectPopupItem,
    PositionOverlay,
    Icons,
    styled,
    InfoToolSelectionEvent,
    InfoToolSelectionMode,
    ObjectDivider,
    NearbyObject,
} from '@keypro/2nd-xp';
import * as models from './models';
import { InfoTool, SearchArea } from '@generated';
import { ToggleObjectController } from './ToggleObjectController';
import { useTranslation } from 'react-i18next';
import { useCenterMenu, useLeftMenu, useRightMenu } from '@stores';
import { InfoObject } from '@components/InfoObject';

interface InfoToolGroups {
    [key: string]: InfoTool[];
}

function getModel(type: string) {
    // Change to switch statement when adding more models
    switch (type) {
        case 'manhole':
            return models.ManholeModel;
        case 'freearea':
            return models.FreeAreaModel;
        case 'splice':
            return models.SpliceModel;
        case 'telecompremise':
            return models.TelecomPremiseModel;
        case 'plan':
            return models.PlanModel;
        case 'freeline':
            return models.FreeLineModel;
        case 'conduit':
            return models.ConduitModel;
        case 'cable':
            return models.CableModel;
        case 'externaldoc':
            return models.DocumentModel;
        case 'pole':
            return models.PoleModel;
        case 'telecomarea':
            return models.TelecomAreaModel;
        case 'servicearea':
            return models.ServiceAreaModel;
        case 'point':
            return models.PointModel;
    }
}

/**
 * The ObjectOverlay component displays content on top of the map.
 * @returns The ObjectOverlay component
 */
export const ObjectOverlay = () => {
    const { setMenuContent } = useRightMenu();
    const { infoToolSelectionMode } = useCenterMenu();
    const { isMenuOpen: isLeftMenuOpen, detailedMenu } = useLeftMenu();
    const { t } = useTranslation();
    const controller = useContext(MapContext)!;

    const [position, setPosition] = useState<[number, number] | undefined>([
        0, 0,
    ]);
    const [offset, setOffset] = useState<number>(0);

    const [infoToolFilter, setInfoToolFilter] = useState<
        | {
              layers: string[];
              searchArea: SearchArea;
          }
        | undefined
    >();

    const infoTools = useGetInfoTools({
        layers: infoToolFilter?.layers,
        searchArea: infoToolFilter?.searchArea,
    });

    const objectRepresentation = useMemo(() => {
        return infoTools.data?.results?.[0];
    }, [infoTools.data]);

    const isInMapView =
        !!objectRepresentation &&
        infoToolSelectionMode === InfoToolSelectionMode.MAP_VIEW;

    useEffect(() => {
        const setPositionAndLocate = (input: string) => {
            if (!isInMapView) {
                const locationParts = input.split(';');
                const geom = controller.wktToGeometry(
                    locationParts[locationParts.length - 1],
                );
                if (geom) {
                    const extent = geom.getExtent();
                    const center: [number, number] = [
                        (extent[0] + extent[2]) / 2,
                        (extent[1] + extent[3]) / 2,
                    ];

                    setPosition(center);
                    controller.pan(center);
                }
            }
        };

        objectRepresentation?.location &&
            setPositionAndLocate(objectRepresentation.location);
    }, [objectRepresentation, controller, isInMapView]);

    const Model = useMemo(() => {
        if (objectRepresentation?.model) {
            return getModel(objectRepresentation.model);
        }
    }, [objectRepresentation]);

    const {
        shouldDisplayInfoToolGroups,
        infoToolGroups,
        shouldExpandable,
        isExpandedByDefault,
    } = useMemo(() => {
        let infoToolGroups: InfoToolGroups = {};

        const shouldDisplayInfoToolGroups =
            infoTools.data?.results && infoTools.data?.results?.length > 1;

        let shouldExpandable = false;
        let isExpandedByDefault = false;

        if (shouldDisplayInfoToolGroups && infoTools.data) {
            const newInfoTools = infoTools.data.results.slice(1);
            shouldExpandable = newInfoTools.length <= 20;
            isExpandedByDefault = newInfoTools.length <= 10;
            infoToolGroups = newInfoTools.reduce<{
                [key: string]: InfoTool[];
            }>((acc, result) => {
                const key = result.model;
                if (key != null) {
                    if (acc[key]) {
                        acc[key].push(result);
                    } else {
                        acc[key] = [result];
                    }
                }
                return acc;
            }, {});
        }

        return {
            shouldDisplayInfoToolGroups,
            infoToolGroups,
            shouldExpandable,
            isExpandedByDefault,
        };
    }, [infoTools.data]);

    useEffect(() => {
        const handleInfoToolSelection = (event: InfoToolSelectionEvent) => {
            setInfoToolFilter({
                layers: event.layers,
                searchArea: event.feature,
            });
        };

        controller?.on('infoToolSelection', handleInfoToolSelection);

        return () => {
            controller?.off('infoToolSelection', handleInfoToolSelection);
        };
    }, [controller]);

    useEffect(() => {
        controller.on('mouseClick', (event) => {
            const activeLayerNames = controller.layers
                .getLayers()
                .getArray()
                .filter(
                    (layer) => layer.getVisible() || layer.get('comboVisible'),
                )
                .map((layer) => layer.get('name'));

            const coordinates: [number, number] = [
                event.coordinate[0],
                event.coordinate[1],
            ];

            setInfoToolFilter({
                layers: activeLayerNames,
                searchArea: {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: coordinates,
                    },
                    properties: null,
                },
            });

            setPosition(coordinates);
        });
    }, [controller]);

    const renderInfoToolGroups = () => {
        if (shouldDisplayInfoToolGroups && infoToolGroups) {
            const list = Object.entries(infoToolGroups);
            return (
                <>
                    <ObjectDivider />
                    <NearbyObject
                        title={t('nearbyObjects')}
                        zoomBtnLabel={t('zoomToAll')}
                        browseBtnLabel={t('browseAll')}
                        zoomBtnIcon={<Icons.ZoomIn />}
                        browseBtnIcon={<Icons.Net />}
                    />
                    {list.map(([key, value], index) => {
                        return (
                            <React.Fragment key={key}>
                                <ToggleObjectController
                                    model={key}
                                    value={value}
                                    shouldExpandable={shouldExpandable}
                                    isExpandedByDefault={isExpandedByDefault}
                                />
                                {index <
                                    Object.keys(infoToolGroups).length - 1 && (
                                    <ObjectDivider />
                                )}
                            </React.Fragment>
                        );
                    })}
                </>
            );
        }
    };

    useEffect(() => {
        let offSetX = 16;
        const navigationBar = document
            .getElementById('navigation-bar')
            ?.getBoundingClientRect();
        offSetX = offSetX + (navigationBar?.width ?? 0);

        if (isLeftMenuOpen || detailedMenu) {
            const leftMenuRect = document
                .getElementById('left-menu')
                ?.getBoundingClientRect();
            offSetX = offSetX + (leftMenuRect?.width ?? 0);
        }
        setOffset(offSetX);
    }, [isInMapView, isLeftMenuOpen, detailedMenu]);

    const renderObjectPopup = () => (
        <>
            {/* Show the temporary popup on the map when the model is not implemented. */}
            {objectRepresentation && !Model && (
                <ObjectPopup>
                    <StyledErrorStub>
                        {t('unsupportedObjectType')}
                    </StyledErrorStub>
                </ObjectPopup>
            )}
            {objectRepresentation && Model && (
                <ObjectPopup data-testid="object-popup">
                    <Model id={objectRepresentation.pk ?? undefined}>
                        {({ isReady, ...props }) => {
                            if (!isReady) {
                                return (
                                    <StyledLoading>
                                        <Icons.Spinner />
                                    </StyledLoading>
                                );
                            }
                            return (
                                <>
                                    <ObjectPopupItem
                                        {...props}
                                        data-testid="object-popup-item"
                                        onClickAction={() => {
                                            setMenuContent(
                                                `InfoObject-${objectRepresentation.model}-${objectRepresentation.pk}`,
                                                <InfoObject
                                                    model={
                                                        objectRepresentation.model!
                                                    }
                                                    id={
                                                        objectRepresentation.pk!
                                                    }
                                                />,
                                            );
                                        }}
                                    />
                                    {renderInfoToolGroups()}
                                </>
                            );
                        }}
                    </Model>
                </ObjectPopup>
            )}
        </>
    );

    return (
        <>
            <PositionOverlay position={position}>
                {!isInMapView && renderObjectPopup()}
            </PositionOverlay>
            {isInMapView && (
                <StyledFixedObjectPopup $offset={offset}>
                    {renderObjectPopup()}
                </StyledFixedObjectPopup>
            )}
        </>
    );
};

const StyledLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 64px;
    svg {
        width: 32px;
        height: 32px;
    }
`;

const StyledErrorStub = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 64px;
    color: ${({ theme }) => theme.colors.error};
    padding-left: 16px;
    padding-right: 16px;
`;

const StyledFixedObjectPopup = styled.div<{
    $offset: number;
}>`
    position: absolute;
    width: 300px;
    top: 16px;
    left: ${(props) => props.$offset}px;
`;
