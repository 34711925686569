import { ModelBaseProps } from '../type';
import { Icons } from '@keypro/2nd-xp';
import { useGetConduits } from '@hooks/keycom';
import { getModelIcon } from '@components';

/**
 * Conduit model props.
 */
export interface ConduitModelProps extends ModelBaseProps {
    id?: string;
}

/**
 * Plan model.
 */
export function ConduitModel(props: Readonly<ConduitModelProps>) {
    const { children, id } = props;

    const conduit = useGetConduits(
        {
            id: id,
        },
        {
            enabled: !!id,
        },
    );

    const conduitData = conduit.data?.[0];

    return (
        <>
            {children({
                isReady: !conduit.isLoading,
                title: 'Conduit',
                tagText: conduitData?.identification ?? '',
                iconObject: getModelIcon('conduit'),
                more: <Icons.More />,
                zoom: <Icons.ZoomIn />,
            })}
        </>
    );
}
