import { getObjectTypes } from '@apis/keycore';
import { ObjectType } from '@generated';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';

/**
 * Hook for object types. Returns a dictionary of object types.
 */
export const useGetObjectTypes = () => {
    return useQuery({
        queryKey: ['getObjectTypes'],
        queryFn: async () => {
            const objectTypes = await getObjectTypes();
            const results = objectTypes.reduce(
                (acc, objectType) => {
                    acc[objectType.identifier!] = objectType;
                    return acc;
                },
                {} as Record<string, ObjectType>,
            );

            // Additional object types that are not returned by the API.
            results['street'] = {
                identifier: 'street',
                name: t('street'),
            };

            return results;
        },
    });
};
