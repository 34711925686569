query GetAddresses($filter: AddressFilter) {
  getAddresses(filter: $filter) {
    addresses {
      id
      streetno
      propertyId
      buildingId
      streetname
      streetnameAlt
      city
      cityAlt
      empty
      municipality
      subdivision
      district
      province
      country
      zipcode
      location
      usagePurpose { id, txt }
      usagePurposeAlt { id, txt }
      apartments { id, apartmentNumber, apartmentLetter, apartmentDivision }
      createdBy
      createdTs
      updatedBy
      updatedTs
      sourceSystem { id, txt }
      sourceSystemKey
    }
  }
}