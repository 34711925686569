query GetMapConfig {
    getMapConfig(name: "KEYCOM_NEW_UI") {
        mapConfig {
            options_v2
            options
            layer_configs {
                pk
                type
                name
                label
                url
                params
                options
                params_v2
                options_v2
                order
                system
                print_url
                model {
                    id
                }
            }
            layer_switcher_configs
        }
    }
}