import { Cable, GetCablesQuery } from '@generated';
import GetCables from './queries/GetCables.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter } from '@apis/utils';

/**
 * Gets the cables.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The cables.
 * @throws {Error} If the request fails.
 */
export const getCables = async (filter: GraphQLFilter) => {
    try {
        const response = await sendGraphQLRequest<GetCablesQuery>(GetCables, {
            filter: filter,
        });

        if (!response.data.getCables) {
            throw Error('No data returned');
        }

        return response.data.getCables.cables?.filter(Boolean) as Cable[];
    } catch (error) {
        throw Error('Failed to get cables: ' + error);
    }
};
