import BaseLayer from 'ol/layer/Base';
import { MapController, LayerGroup } from '@keypro/2nd-xp';
import { ExtendedTreeNode } from './LayerTreeView';

/**
 * Get favourite layers from local storage
 * @param favourite
 * @returns
 */
export const getFavouriteLayerIds = (favourite: boolean): string[] => {
    if (!favourite) return [];
    const storedFavourites = localStorage.getItem('favouriteLayers');
    return storedFavourites ? JSON.parse(storedFavourites) : [];
};

/**
 * Check if layer is favourite
 * @param layerId
 * @returns
 */
export const isLayerFavourite = (layerId: string): boolean => {
    const favouriteLayerIds = getFavouriteLayerIds(true);
    return favouriteLayerIds.includes(layerId);
};

export const setFavouriteLayer = (layerId: string, favourite: boolean) => {
    let favouriteLayerIds = getFavouriteLayerIds(true);
    if (favourite) {
        favouriteLayerIds.push(layerId);
    } else {
        favouriteLayerIds = favouriteLayerIds.filter(
            (id: string) => id !== layerId,
        );
    }
    localStorage.setItem('favouriteLayers', JSON.stringify(favouriteLayerIds));
};

/**
 * Filter layers based on isBaseLayer and favouriteLayerIds
 * @param layers
 * @param isBaseLayer
 * @param favouriteLayerIds
 * @returns
 */
export const filterLayers = (
    layers: BaseLayer[],
    isBaseLayer: boolean,
    favouriteLayerIds: string[],
): BaseLayer[] => {
    const filteredLayers = layers.filter(
        (layer) =>
            Boolean(layer.get('isBaseLayer')) === isBaseLayer &&
            (!favouriteLayerIds.length ||
                favouriteLayerIds.includes(layer.get('name'))),
    );

    return filteredLayers;
};

/**
 * Map layer data to ExtendedTreeNode
 * @param layersArray
 * @returns
 */
export const mapLayerData = (layersArray: BaseLayer[]): ExtendedTreeNode[] => {
    return layersArray
        .map((layer) => {
            if (
                !layer.get('name') ||
                !layer.get('label') ||
                layer.get('isComboLayer')
            )
                return;
            return {
                id: layer.get('name'),
                selected: layer.getVisible() || layer.get('comboVisible'),
                name: layer.get('label'),
                label: layer.get('name'),
            };
        })
        .filter(Boolean) as ExtendedTreeNode[];
};

/**
 * Construct tree view data for layers
 * @param controller
 * @param favourite
 * @returns
 */
export const constructTreeViewData = (
    controller: MapController,
    favourite: boolean = false,
): ExtendedTreeNode[] => {
    const layers = controller.layers.getLayers().getArray();
    const layerGroups = controller.layers.getLayerGroups();
    const favouriteLayerIds = getFavouriteLayerIds(favourite);

    if (favourite && !favouriteLayerIds.length) return [];

    // If layer groups are defined in the config, use them
    if (layerGroups && layerGroups.length > 0) {
        const visibleLayers = controller.layers.getActiveLayerNames();
        if (favourite) {
            return layerTreeDataFromLayerGroups(
                layerGroups,
                visibleLayers,
                favouriteLayerIds,
            );
        }
        return layerTreeDataFromLayerGroups(layerGroups, visibleLayers);
    }

    // If layer groups are not defined in the config, use the layers directly
    const baseLayers = filterLayers(layers, true, favouriteLayerIds);
    const otherLayers = filterLayers(layers, false, favouriteLayerIds);

    const treeData = [
        {
            id: 'group_basemaps',
            name: 'Basemaps',
            basegroup: true,
            // radio: true,
            children: mapLayerData(baseLayers),
        },
        {
            id: 'group_layers',
            name: 'Layers',
            basegroup: true,
            children: mapLayerData(otherLayers),
        },
    ];
    return treeData.filter((item) => item.children.length > 0);
};

/**
 * Converts LayerGroup to Tree view data
 */
const processNode = (
    node: LayerGroup,
    visibleLayers: string[],
    favouriteLayerIds?: string[],
): ExtendedTreeNode | null => {
    const processedNode: ExtendedTreeNode = {
        id: node.layer !== '' ? node.layer : node.id,
        name: node.text,
        selected: visibleLayers.includes(node.layer),
        expanded: favouriteLayerIds ? true : node.expanded,
    };

    if (node.children && node.children.length > 0) {
        const filteredChildren = node.children
            .map((child) =>
                processNode(child, visibleLayers, favouriteLayerIds),
            )
            .filter((child) => child !== null);

        if (filteredChildren.length > 0) {
            processedNode.children = filteredChildren;
        }
    }

    if (
        favouriteLayerIds &&
        !favouriteLayerIds.includes(processedNode.id) &&
        !processedNode.children
    ) {
        return null;
    }

    return processedNode;
};

/**
 * Recursively convert LayerGroups to tree view data
 */
const layerTreeDataFromLayerGroups = (
    layerGroups: LayerGroup[],
    visibleLayers: string[],
    favouriteLayerIds?: string[],
): ExtendedTreeNode[] => {
    const treeNodes = layerGroups
        .map((node) => processNode(node, visibleLayers, favouriteLayerIds))
        .filter((node) => node !== null);

    treeNodes.forEach((node) => {
        node.basegroup = true;
    });

    return treeNodes;
};

/**
 * Check if tree view node has children
 */
export const hasChildren = (id: string, nodes: ExtendedTreeNode[]): boolean => {
    for (const node of nodes) {
        if (node.id === id) {
            return !!(node.children && node.children.length > 0);
        }
        if (node.children && node.children.length > 0) {
            if (hasChildren(id, node.children)) {
                return true;
            }
        }
    }
    return false;
};
