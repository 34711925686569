import { useQuery } from '@tanstack/react-query';
import { getTelecomPremises } from 'src/apis/keycom';

export interface UseGetTelecomPremisesOptions {
    id?: string;
}

export interface UseGetTelecomPremisesConfig {
    enabled?: boolean;
}

/**
 * Telecom Premise hook to use react-query.
 */
export function useGetTelecomPremises(
    options: UseGetTelecomPremisesOptions,
    { enabled = true }: UseGetTelecomPremisesConfig = {},
) {
    const { id } = options;

    return useQuery({
        queryKey: ['map', 'telecompremise', { id }],
        queryFn: async () => {
            return getTelecomPremises({
                id: { eq: id },
            });
        },
        enabled,
    });
}
