import { DocumentNode, print } from 'graphql';
import i18n from '@i18n';
import axios from 'axios';

interface GraphQLError {
    message: string;
    locations: { line: number; column: number }[];
    path: string[];
}

interface GraphQLResponse<TData> {
    data: TData;
    errors?: GraphQLError[];
}

type Variables = Record<string, unknown>;

export class GraphQLRequestError extends Error {
    status?: number;
    errors?: GraphQLError[];

    constructor(message: string, status?: number, errors?: GraphQLError[]) {
        super(message);
        this.status = status;
        this.errors = errors;
    }
}

export const graphqlInstance = axios.create({
    baseURL: '/graphql',
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

graphqlInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.status === 500) {
            alert('Internal server error. Gateway responded with 500');
        }
    },
);

export async function sendGraphQLRequest<TData>(
    query: DocumentNode,
    variables?: Variables,
): Promise<GraphQLResponse<TData>> {
    const requestBody = {
        query: print(query),
        variables: variables,
    };
    const response = await graphqlInstance({
        data: requestBody,
        headers: { 'Accept-Language': i18n.languages.toString() },
    });

    if (response.status !== 200) {
        throw new GraphQLRequestError(
            `GraphQL request failed with status: ${response.status}`,
            response.status,
        );
    }

    if (response.data.errors) {
        // Could handle expireded session here
        throw new GraphQLRequestError(
            `GraphQL request failed with error: ${JSON.stringify(response.data.errors)}`,
            undefined,
            response.data.errors,
        );
    }

    if (response.data.data === undefined || response.data.data === null) {
        throw new GraphQLRequestError(
            'GraphQL response data is undefined or null',
        );
    }
    return response.data as GraphQLResponse<TData>;
}

export default { sendGraphQLRequest, GraphQLRequestError };
