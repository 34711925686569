import { getTelecomPremises } from '@apis/keycom';
import { FormConfig } from '../types';
import { addressGroup, planGroup, telecomAreaGroup } from '../groups';

const config: FormConfig = {
    gqlType: 'TelecomPremise',
    model: 'telecompremise',
    groups: [
        {
            name: 'objectData',
            fields: [
                {
                    name: 'owner',
                    filter: {
                        groupname: { eq: 'PREM_OWNER' },
                    },
                },
                {
                    name: 'renter',
                    filter: {
                        groupname: { eq: 'PREM_OWNER' },
                    },
                },
                'identification',
                'name',
                {
                    name: 'deviceRoom',
                    filter: {
                        groupname: { eq: 'PREM_PLACEMENT' },
                    },
                },
                {
                    name: 'usageState',
                    filter: {
                        groupname: { eq: 'PREM_STATE' },
                    },
                },
                'install_year',
                'capacity',
                {
                    name: 'importance',
                    filter: {
                        groupname: { eq: 'PREM_IMPORTANCE' },
                    },
                },
                {
                    name: 'diagramConfirm',
                    component: 'radio',
                    filter: {
                        groupname: { eq: 'MANHDIAG_CONFIRMED' },
                    },
                },
                {
                    name: 'remarks',
                    component: 'textarea',
                    translationKey: 'addComment',
                },
                {
                    name: 'technicalInfo',
                    component: 'textarea',
                    translationKey: 'addTechnicalInfo',
                },
            ],
        },
        addressGroup,
        telecomAreaGroup,
        planGroup,
        {
            name: 'common',
            fields: [
                {
                    name: 'locationAccuracy',
                    filter: {
                        groupname: { eq: 'LOCATION_ACCURACY' },
                    },
                },
                {
                    name: 'heightAccuracy',
                    filter: {
                        groupname: { eq: 'H_ACCURACY' },
                    },
                },
                'historyDate',
                {
                    name: 'mappingMethod',
                    filter: {
                        groupname: { eq: 'MAPPING_METHOD' },
                    },
                },
                'survey_amount',
                {
                    name: 'safetyDistance',
                    filter: {
                        groupname: { eq: 'SAFETY_DISTANCE' },
                    },
                },
                'mapper',
            ],
        },
    ],
    functions: {
        get: getTelecomPremises,
    },
    queryCollection: 'telecomPremises',
};

export default config;
