query getFreeLines($filter: FreeLineFilter) {
  getFreeLines(filter: $filter) {
    freeLines {
      id
      color
      created_by
      created_date
      updated_by
      updated_date
      location
      lineType {id, txt}
      line_width
      state {id, txt}
      length
      identification
      description
      plan {
        id
        name
      }
    }
  }
}
