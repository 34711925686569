import { ModelBaseProps } from '../type';
import { Icons } from '@keypro/2nd-xp';
import { useGetTelecomAreas } from '@hooks/keycom';
import { getModelIcon } from '@components';

/**
 * Telecom Area model props.
 */
export interface TelecomAreaModelProps extends ModelBaseProps {
    id?: string;
}

/**
 * Telecom Premise model.
 */
export function TelecomAreaModel(props: Readonly<TelecomAreaModelProps>) {
    const { children, id } = props;

    const telecomArea = useGetTelecomAreas(
        {
            id: id,
        },
        {
            enabled: !!id,
        },
    );

    const telecomAreaData = telecomArea.data?.[0];

    return (
        <>
            {children({
                isReady: !telecomArea.isLoading,
                title: 'Telecom Area',
                tagText: telecomAreaData?.name ?? '',
                iconObject: getModelIcon('telecomarea'),
                more: <Icons.More />,
                zoom: <Icons.ZoomIn />,
            })}
        </>
    );
}
