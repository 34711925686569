import { useQuery } from '@tanstack/react-query';
import { getCables } from 'src/apis/keycom';

export interface UseGetCablesOptions {
    id?: string;
}

export interface UseGetCablesConfig {
    enabled?: boolean;
}

/**
 * Cable hook to use react-query.
 */
export function useGetCables(
    options: UseGetCablesOptions,
    { enabled = true }: UseGetCablesConfig = {},
) {
    const { id } = options;

    return useQuery({
        queryKey: ['map', 'cables', { id }],
        queryFn: async () => {
            return getCables({
                id: { eq: options.id },
            });
        },
        enabled,
    });
}
