import { ModelBaseProps } from '../type';
import { Icons } from '@keypro/2nd-xp';
import { useGetPlans } from '@hooks/keycore/plan';
import { getModelIcon } from '@components';

/**
 * Plan model props.
 */
export interface PlanModelProps extends ModelBaseProps {
    id?: string;
}

/**
 * Plan model.
 */
export function PlanModel(props: Readonly<PlanModelProps>) {
    const { children, id } = props;

    const plan = useGetPlans(
        {
            filter: {
                id: {
                    eq: id,
                },
            },
        },
        {
            enabled: !!id,
        },
    );

    const planData = plan.data?.[0];

    return (
        <>
            {children({
                isReady: !plan.isLoading,
                title: 'Plan',
                tagText: planData?.type?.txt ?? '',
                iconObject: getModelIcon('plan'),
                more: <Icons.More />,
                zoom: <Icons.ZoomIn />,
            })}
        </>
    );
}
