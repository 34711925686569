import { getDocuments } from '@apis/keycore';
import { DocumentsFilter } from '@generated';
import { useQuery } from '@tanstack/react-query';

export interface UseGetDocumentsOptions {
    filter?: DocumentsFilter;
}

export interface UseGetDocumentsConfig {
    enabled?: boolean;
}

/**
 * Document hook to use react-query.
 */
export function useGetDocuments(
    options: UseGetDocumentsOptions,
    { enabled = true }: UseGetDocumentsConfig = {},
) {
    const { id } = options.filter || {};

    return useQuery({
        queryKey: ['map', 'documents', { id }],
        queryFn: async () => {
            return getDocuments({
                id,
            });
        },
        enabled,
    });
}
