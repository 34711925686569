import { GetObjectTypesQuery, ObjectType } from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetObjectTypes from './queries/GetObjectTypes.graphql';

export const getObjectTypes = async (): Promise<ObjectType[]> => {
    try {
        const response =
            await sendGraphQLRequest<GetObjectTypesQuery>(GetObjectTypes);

        return response.data.getObjectTypes?.objectTypes?.filter(
            Boolean,
        ) as ObjectType[];
    } catch (error) {
        throw Error('Failed to get object types: ' + error);
    }
};
