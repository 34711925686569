import { GetAddressesQuery, Address, AddressFilter } from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetAddresses from './queries/GetAddresses.graphql';

/**
 * Gets the addresses.
 * @returns Addresses.
 * @throws {Error} If the request fails.
 */
export const getAddresses = async (
    filter?: AddressFilter,
): Promise<Address[]> => {
    try {
        const response = await sendGraphQLRequest<GetAddressesQuery>(
            GetAddresses,
            { filter: filter },
        );

        if (!response.data.getAddresses) {
            throw Error('No data returned');
        }

        return response.data.getAddresses.addresses?.filter(
            Boolean,
        ) as Address[];
    } catch (error) {
        throw Error('Failed to get addresses: ' + error);
    }
};
