import { FormConfig } from '../types';
import { getFreeAreas } from '@apis/keycore';
import { planGroup } from '../groups';

const config: FormConfig = {
    gqlType: 'FreeArea',
    model: 'freearea',
    groups: [
        {
            name: 'objectData',
            fields: [
                'name',
                'color',
                { name: 'area_size', translationKey: 'size' },
                {
                    name: 'type',
                    filter: {
                        groupname: { eq: 'FREEAREA_TYPE' },
                    },
                },
                {
                    name: 'state',
                    filter: {
                        groupname: { eq: 'FREEAREA_STATE' },
                    },
                },
            ],
        },
        planGroup,
    ],
    functions: {
        get: getFreeAreas,
    },
    queryCollection: 'freeLines',
};

export default config;
