import { GraphQLObjectField } from '@apis/introspection';
import {
    styled,
    NumberInput,
    Input,
    Section,
    Button,
    SelectOptionItem,
} from '@keypro/2nd-xp';
import {
    FieldComponent,
    FilteredQueryFunction,
    FormField,
    FormData,
    getFormConfig,
    LabelFormatter,
    FormGroup as Group,
} from '@form-configs';
import { t } from 'i18next';
import { useFormBuilder } from '@stores';
import {
    LazySelect,
    AddressInput,
    Checkbox,
    LazyRadioGroup,
    DateInput,
    TextArea,
    RangeNumberInput,
    Link,
} from './form-components';
import { GraphQLFilter } from '@apis/utils';
import { Address, Apartment } from '@generated';
import { toCamelCase } from './utils';
import { HTMLAttributes } from 'react';

/**
 * GraphQL object reference.
 */
export interface ObjectReference {
    id: string;
}

/**
 * Props for FormBuilder.
 */
export interface FormBuilderProps extends HTMLAttributes<HTMLDivElement> {
    /** GraphQL type to build a form for. */
    gqlType: string;
    /** Initial form data. */
    data: FormData;
    customGroup?: Group[];
    onChangeValue?: (name: string, value: unknown) => void;
}

const FormGroup = styled.div`
    margin: 0 2px;

    table {
        border-spacing: 0 4px;
        margin: -4px 0 -6px 0;
        width: 100%;
        table-layout: fixed;
    }

    .same-row {
        display: flex;
        gap: 8px;
        width: 100%;
    }

    .same-row > div {
        width: 200px;
    }

    th {
        text-align: left;
        font-weight: normal;
        width: 40%;
    }
`;

/**
 * A component for building forms automatically based on the provided GraphQL type.
 * The GraphQL type must have a corresponding form configuration in the form-configs
 * directory before FormBuilder can be used with it.
 */
const FormBuilder = (props: FormBuilderProps): JSX.Element => {
    const { gqlType, data, customGroup, onChangeValue, ...rest } = props;
    const { types } = useFormBuilder();
    const searchString = 'Search';
    let type;

    if (gqlType.endsWith(searchString)) {
        const tempGqlType = gqlType.slice(0, -searchString.length);
        type = types[tempGqlType];
    } else {
        type = types[gqlType];
    }

    if (!type) {
        throw new Error(`Type not found: ${gqlType}`);
    }

    const configs = getFormConfig(gqlType);
    const groups = [] as JSX.Element[];

    if (!configs) {
        throw new Error(`No form configuration found for type: ${gqlType}`);
    }

    // If customGroup is provided, use it instead of getting from the default
    const tempGroup: Group[] = customGroup ?? configs.groups;

    tempGroup.forEach((group) => {
        const rows = [] as JSX.Element[];
        group.fields.forEach((fieldDefinition) => {
            const fieldConfig: FormField | undefined =
                typeof fieldDefinition === 'object'
                    ? fieldDefinition
                    : undefined;

            const fieldName =
                typeof fieldDefinition === 'string'
                    ? fieldDefinition
                    : fieldDefinition.name;

            const field = type.fields.find((f) => f.name === fieldName);
            rows.push(getFormRow(data, field, fieldConfig, onChangeValue));
        });

        // Put translation key into a variable to prevent i18next-parser from gathering it
        const groupTranslationKey = group.translationKey
            ? group.translationKey
            : group.name;

        groups.push(
            <FormGroup
                key={group.name}
                data-testid={`form-group-${group.name}`}
            >
                <Section title={t(groupTranslationKey)} open={true}>
                    <table>
                        <tbody>{rows}</tbody>
                    </table>
                </Section>
            </FormGroup>,
        );
    });

    return <div {...rest}>{groups}</div>;
};

/**
 * Gets the table row for a form field based on its type and configuration.
 * @param field Field to build a form row for.
 * @param config Configuration for the field.
 */
const getComponentType = (
    field?: GraphQLObjectField,
    config?: FormField,
): string => {
    let componentType: FieldComponent = config?.component ?? 'auto';

    const componentTypeMap: Record<string, FieldComponent> = {
        String: 'text',
        Float: 'number',
        Int: 'number',
        Number: 'number',
        Boolean: 'checkbox',
        DateTime: 'date',
        Apartment: 'address',
        TxtConstant: 'combobox',
        URL: 'link',
    };

    const name = field?.name ?? config?.name;

    if (!name) {
        throw new Error('Field name is required');
    }

    if (config?.custom && !config.component) {
        throw new Error('Custom fields must have a component type');
    }

    if (componentType === 'auto') {
        if (!field) {
            throw new Error(
                `Component type 'auto' can only be used with fields belonging to schema (field: ${name})`,
            );
        }

        if (componentTypeMap[field.type]) {
            componentType = componentTypeMap[field.type];
        } else if (!field.isScalar) {
            componentType = 'objectReference';
        }

        if (!componentType) {
            throw new Error(`Unsupported field type: ${field.type}`);
        }
    }

    if (
        config?.filter &&
        componentType !== 'combobox' &&
        componentType !== 'combobox-multi' &&
        componentType !== 'radio'
    ) {
        throw new Error(
            `Filtering can only be used with 'combobox' or 'radio' component type (field: ${name})`,
        );
    }

    return componentType;
};

/**
 * Queries the txt constants and returns them as options for a combobox.
 * @param queryFunction Function to query the txt constants.
 * @param filter Filter to apply to the query.
 */
const queryOptions = async (
    queryFunction: FilteredQueryFunction,
    filter?: GraphQLFilter,
    labelFormatter?: LabelFormatter,
) => {
    const items = await queryFunction(filter);
    return arrayToOptions(items, labelFormatter);
};

/**
 * Converts an array of form data to an array of select options.
 * @param array Array of form data.
 * @param labelFormatter Optional formatter for the option label.
 */
const arrayToOptions = (
    array: FormData[],
    labelFormatter?: LabelFormatter,
): SelectOptionItem[] => {
    let sortByName = true;

    if (array.length && array[0].orderno !== undefined) {
        array.sort((a, b) => Number(a.orderno ?? 0) - Number(b.orderno ?? 0));
        sortByName = false;
    }

    const items = array.map((item) => {
        let label: string;

        if (labelFormatter) {
            label = labelFormatter(item);
        } else {
            label = (item.txt ?? item.name ?? item.id) as string;
        }

        return { value: item.id, label: label } as SelectOptionItem;
    });

    if (sortByName) {
        return items.sort((a, b) =>
            a.label.localeCompare(b.label, undefined, {
                numeric: true,
                sensitivity: 'base',
            }),
        );
    } else {
        return items;
    }
};

/**
 * Gets the table row for a form field based on its type and configuration.
 * @param field Field to build a form row for.
 * @param config Configuration for the field.
 * @param formData Form data.
 */
const getFormRow = (
    formData: FormData,
    field?: GraphQLObjectField,
    config?: FormField,
    onChangeValue?: (name: string, value: unknown) => void,
) => {
    const name = (field?.name ?? config?.name)!;
    const componentType = getComponentType(field, config);
    const value =
        componentType !== 'custom' && formData && name ? formData[name] : null;

    const queryFunction = async (labelFormatter?: LabelFormatter) => {
        if (!field) {
            throw new Error(
                `Field is required for querying options (field: ${name})`,
            );
        }

        const formFieldConfig = getFormConfig(field.type);

        return await queryOptions(
            formFieldConfig.functions.get,
            config?.filter,
            labelFormatter,
        );
    };

    const getFixedOptions = async (labelFormatter?: LabelFormatter) => {
        if (!Array.isArray(value)) {
            throw new Error(
                `Fixed options can only be used with array values (field: ${name})`,
            );
        }

        return Promise.resolve(
            arrayToOptions(value as FormData[], labelFormatter),
        );
    };

    // Put translation key into a variable to prevent i18next-parser from gathering it
    const translationKey = config?.translationKey
        ? config.translationKey
        : toCamelCase(name);
    const label = t(translationKey);

    switch (componentType) {
        case 'text':
            return (
                <tr key={name}>
                    {label && (
                        <th data-testid={`form-row-header-${label}`}>
                            {label}
                        </th>
                    )}
                    <td data-testid={`form-row-input-${label}`}>
                        <Input
                            inputProps={{
                                name: name,
                                defaultValue: value as string,
                                onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>,
                                ) => onChangeValue?.(name, e.target.value),
                            }}
                        />
                    </td>
                </tr>
            );
        case 'textarea':
            return (
                <tr key={name}>
                    <td colSpan={2}>
                        <TextArea
                            inputProps={{
                                name: name,
                                placeholder: config?.translationKey
                                    ? t(config.translationKey)
                                    : t('addNote'),
                                defaultValue: value as string,
                                rows: 3,
                                onChange: (
                                    e: React.ChangeEvent<HTMLTextAreaElement>,
                                ) => onChangeValue?.(name, e.target.value),
                            }}
                            multiline
                        />
                    </td>
                </tr>
            );
        case 'number':
            return (
                <tr key={name}>
                    {label && (
                        <th data-testid={`form-row-header-${label}`}>
                            {label}
                        </th>
                    )}
                    <td data-testid={`form-row-input-${label}`}>
                        <NumberInput
                            value={value as number}
                            onChange={(value) =>
                                onChangeValue?.(name, value.toString())
                            }
                        />
                    </td>
                </tr>
            );
        case 'combobox':
            return (
                <tr key={name}>
                    {label && (
                        <th data-testid={`form-row-header-${label}`}>
                            {label}
                        </th>
                    )}
                    <td>
                        <LazySelect
                            queryFunction={queryFunction}
                            initialValue={(value as ObjectReference)?.id}
                            labelFormatter={config?.labelFormatter}
                            onChange={(value: unknown) => {
                                onChangeValue?.(name, value);
                            }}
                        />
                    </td>
                </tr>
            );
        case 'combobox-multi':
            return (
                <tr key={name}>
                    {label && (
                        <th data-testid={`form-row-header-${label}`}>
                            {label}
                        </th>
                    )}
                    <td>
                        <LazySelect
                            queryFunction={queryFunction}
                            initialValue={(value as ObjectReference)?.id}
                            isMultiSelect={true}
                            onChange={(value: unknown) => {
                                onChangeValue?.(name, value);
                            }}
                        />
                    </td>
                </tr>
            );
        case 'radio':
            return (
                <tr key={name}>
                    {label && (
                        <th data-testid={`form-row-header-${label}`}>
                            {label}
                        </th>
                    )}
                    <td data-testid={`form-row-input-${label}`}>
                        <LazyRadioGroup
                            name={name}
                            queryFunction={queryFunction}
                            initialValue={(value as ObjectReference)?.id}
                            onChange={(value) => onChangeValue?.(name, value)}
                        />
                    </td>
                </tr>
            );
        case 'checkbox':
            return (
                <tr key={name}>
                    <td colSpan={2}>
                        <div
                            className="same-row"
                            data-testid={`form-row-input-${label}`}
                        >
                            <Checkbox
                                name={name}
                                type="checkbox"
                                label={label}
                                checked={value as boolean}
                                onChange={(checked) =>
                                    onChangeValue?.(name, checked)
                                }
                            />
                        </div>
                    </td>
                </tr>
            );
        case 'date':
            return (
                <tr key={name}>
                    {label && (
                        <th data-testid={`form-row-header-${label}`}>
                            {label}
                        </th>
                    )}
                    <td data-testid={`form-row-input-${label}`}>
                        <DateInput
                            placeholder={t('selectDate')}
                            initialValue={
                                value ? new Date(value as string) : undefined
                            }
                            onChange={(date) =>
                                onChangeValue?.(name, date?.toISOString())
                            }
                        />
                    </td>
                </tr>
            );
        case 'address':
            return (
                <tr key={name}>
                    <td colSpan={2}>
                        <AddressInput
                            data={(value as Apartment)?.address as Address}
                        />
                    </td>
                </tr>
            );
        case 'link':
            return (
                <tr key={name}>
                    {label && <th>{label}</th>}
                    <td>
                        {config?.valueFormatter?.(formData, value) || (
                            <Link
                                url={value as string}
                                text={value as string}
                                data-tooltip-left={value as string}
                            />
                        )}
                    </td>
                </tr>
            );
        case 'objectReference':
            return (
                <tr key={name}>
                    {label && <th>{label}</th>}
                    <td style={{ width: label ? 'auto' : '100%' }}>
                        <div className="same-row">
                            <LazySelect
                                queryFunction={
                                    Array.isArray(value)
                                        ? getFixedOptions
                                        : queryFunction
                                }
                                initialValue={(value as ObjectReference)?.id}
                                labelFormatter={config?.labelFormatter}
                                onChange={(value: unknown) => {
                                    onChangeValue?.(name, { id: value });
                                }}
                            />
                            <Button kind="secondary">{t('openForm')}</Button>
                        </div>
                    </td>
                </tr>
            );
        case 'range-number':
            return (
                <tr key={name}>
                    {label && (
                        <th data-testid={`form-row-header-${label}`}>
                            {label}
                        </th>
                    )}
                    <td data-testid={`form-row-input-${label}`}>
                        <RangeNumberInput
                            initialMinValue={(value as { min: number })?.min}
                            initialMaxValue={(value as { max: number })?.max}
                            onChange={(value) => {
                                onChangeValue?.(name, value);
                            }}
                        />
                    </td>
                </tr>
            );
        default:
            throw new Error(
                `Unhandled component type: ${componentType} (field: ${name})`,
            );
    }
};

export default FormBuilder;
