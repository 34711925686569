import { ModelBaseProps } from '../type';
import { Icons } from '@keypro/2nd-xp';
import { useGetPoles } from '@hooks/keycom';
import { getModelIcon } from '@components';

/**
 * Pole model props.
 */
export interface PoleModelProps extends ModelBaseProps {
    id?: string;
}

/**
 * Pole model.
 */
export function PoleModel(props: Readonly<PoleModelProps>) {
    const { children, id } = props;

    const pole = useGetPoles(
        {
            id: id,
        },
        {
            enabled: !!id,
        },
    );

    const poleData = pole.data?.[0];

    return (
        <>
            {children({
                isReady: !pole.isLoading,
                title: 'Pole',
                tagText: poleData?.pole_number?.toString() ?? '',
                iconObject: getModelIcon('pole'),
                more: <Icons.More />,
                zoom: <Icons.ZoomIn />,
            })}
        </>
    );
}
