import { search } from '@apis/keycore';
import { useQuery } from '@tanstack/react-query';

/**
 * Hook for universal search query.
 */
export const useSearch = (term: string) => {
    return useQuery({
        queryKey: ['search', term],
        queryFn: async () => search(term),
        enabled: !!term,
    });
};
