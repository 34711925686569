import { useQuery } from '@tanstack/react-query';
import { getServiceAreas } from 'src/apis/keycom';

export interface UseGetServiceAreasOptions {
    id?: string;
}

export interface UseGetServiceAreasConfig {
    enabled?: boolean;
}

/**
 * Service Area hook to use react-query.
 */
export function useGetServiceAreas(
    options: UseGetServiceAreasOptions,
    { enabled = true }: UseGetServiceAreasConfig = {},
) {
    const { id } = options;

    return useQuery({
        queryKey: ['map', 'serviceAreas', { id }],
        queryFn: async () => {
            return getServiceAreas({
                id: {
                    eq: options.id,
                },
            });
        },
        enabled,
    });
}
