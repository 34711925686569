import { PlanFilter } from '@generated';
import { useQuery } from '@tanstack/react-query';
import { getPlans } from 'src/apis/keycore';

export interface UseGetPlansOptions {
    filter?: PlanFilter;
}

export interface UseGetPlansConfig {
    enabled?: boolean;
}

/**
 * Plan hook to use react-query.
 */
export function useGetPlans(
    options: UseGetPlansOptions,
    { enabled = true }: UseGetPlansConfig = {},
) {
    const { id } = options.filter || {};

    return useQuery({
        queryKey: ['map', 'plans', { id }],
        queryFn: async () => {
            return getPlans({
                id,
            });
        },
        enabled,
    });
}
