import {
    Icons,
    InfoToolMenu,
    MoreMenu,
    MenuDivider,
    MeasureToolMenu,
    styled,
    LocateMeButton,
} from '@keypro/2nd-xp';
import { t } from 'i18next';
import { useCenterMenu, useLeftMenu } from '@stores';
import {
    getInfoToolMenuProps,
    getLocateMeButtonProps,
    getMeasureToolMenuProps,
    getMoreMenuProps,
} from './Common';
import { useMobile } from '@hooks/responsive';

const MobileAppToolbar = (): JSX.Element => {
    const { setInfoToolSelectionMode } = useCenterMenu();
    const isMobile = useMobile();

    const measureToolMenuProps = getMeasureToolMenuProps();
    const moreMenuProps = getMoreMenuProps();
    const infoToolMenuProps = getInfoToolMenuProps();
    const locateMeButtonProps = getLocateMeButtonProps();
    const { isMenuOpen } = useLeftMenu();

    if (!isMobile) return <></>;

    return (
        <StyledContainer id="mobile-toolmenu" style={{}}>
            <StyledLocatedMe $isMenuOpen={isMenuOpen}>
                <LocateMeButton {...locateMeButtonProps} />
            </StyledLocatedMe>
            <StyledMenuTools $isMenuOpen={isMenuOpen}>
                <MoreMenu
                    {...moreMenuProps}
                    align="right"
                    buttonIcon={<Icons.InfoMapView />}
                    upward
                >
                    <InfoToolMenu
                        {...infoToolMenuProps}
                        asMenuItems
                        menuItemsGroupTitle={t('Info')}
                        onChangeMode={(mode) => {
                            setInfoToolSelectionMode(mode);
                        }}
                    />
                    <MenuDivider />
                    <MeasureToolMenu
                        {...measureToolMenuProps}
                        asMenuItems
                        menuItemsGroupTitle={t('Measure')}
                    />
                    <MenuDivider />
                </MoreMenu>
            </StyledMenuTools>
        </StyledContainer>
    );
};

export default MobileAppToolbar;

interface MobileProps {
    $isMenuOpen?: boolean;
}

const StyledContainer = styled.div`
    z-index: 2;
`;

const StyledLocatedMe = styled.div<MobileProps>`
    box-sizing: border-box;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.neutral['20']};
    bottom: ${({ $isMenuOpen }) => ($isMenuOpen ? 'calc(50% + 40px)' : '72px')};
    right: 16px;
    width: 40px;
    height: 40px;
    overflow: hidden;

    & button {
        width: 40px;
        height: 40px;
    }

    & * > svg {
        width: 20px;
        height: 20px;
    }
`;

const StyledMenuTools = styled.div<MobileProps>`
    box-sizing: border-box;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.accents.blue['10']};
    bottom: ${({ $isMenuOpen }) => ($isMenuOpen ? 'calc(50% - 16px)' : '16px')};
    right: 16px;
    width: 40px;
    height: 40px;

    & * > button {
        box-shadow: none;
        &:enabled:hover {
            background-color: ${(props) =>
                props.theme.colors.accents.blue['10']};
        }
    }
    & .dropdown > button > svg {
        width: 20px;
        height: 20px;
    }
    // Remove deprecated upward and find a better solution for dropdown, then remove this
    & .dropdown > .dropdown-dropdown {
        ${({ $isMenuOpen }) => $isMenuOpen && 'top: 300px; right: 70px;'};
    }
`;
