import { FreeLine, FreeLineFilter, GetFreeLinesQuery } from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetFreeLines from './queries/GetFreeLines.graphql';

/**
 * Gets the freelines.
 * @returns {Promise<Plan[]>} The freelines.
 * @throws {Error} If the request fails.
 */
export const getFreeLines = async (
    filter?: FreeLineFilter,
): Promise<FreeLine[]> => {
    try {
        const response = await sendGraphQLRequest<GetFreeLinesQuery>(
            GetFreeLines,
            {
                filter: filter,
            },
        );

        if (!response.data.getFreeLines) {
            throw Error('No data returned');
        }

        return response.data.getFreeLines.freeLines?.filter(
            Boolean,
        ) as FreeLine[];
    } catch (error) {
        throw Error('Failed to get free lines: ' + error);
    }
};
