import { FormConfig } from '../types';
import { getPlans } from '@apis/keycore';

const config: FormConfig = {
    gqlType: 'Plan',
    model: 'plan',
    groups: [
        {
            name: 'objectData',
            fields: [
                { name: 'name', translationKey: 'identification' },
                {
                    name: 'type',
                    filter: {
                        groupname: { eq: 'PLAN_TYPE' },
                    },
                },
                {
                    name: 'state',
                    filter: {
                        groupname: { eq: 'PLAN_STATE' },
                    },
                },
                'begin_date',
                'expire_date',
                { name: 'description', component: 'textarea' },
            ],
        },
    ],
    functions: {
        get: getPlans,
    },
    queryCollection: 'plans',
};

export default config;
