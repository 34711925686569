import { FormConfig } from '../types';
import { getApartments } from '@apis/keycore';

const config: FormConfig = {
    gqlType: 'Apartment',
    model: 'apartment',
    groups: [
        {
            name: 'objectData',
            fields: [
                { name: 'apartmentLetter', translationKey: 'letter' },
                { name: 'apartmentNumber', translationKey: 'number' },
                { name: 'apartmentDivision', translationKey: 'division' },
                {
                    name: 'usagePurpose',
                    filter: {
                        groupname: { eq: 'APARTMENT_USAGE' },
                    },
                },
                {
                    name: 'usagePurposeAlt',
                    filter: {
                        groupname: { eq: 'KN_ADDR_SPEC' },
                    },
                },
                {
                    name: 'usageState',
                    filter: {
                        groupname: { eq: 'APARTMENT_STATUS' },
                    },
                },
            ],
        },
        {
            name: 'dataSource',
            fields: [
                'sourceId',
                {
                    name: 'sourceSystem',
                    filter: {
                        groupname: { eq: 'SOURCE_SYSTEM' },
                    },
                },
                'sourceSystemKey',
            ],
        },
    ],
    functions: {
        get: getApartments,
    },
    queryCollection: 'apartments',
};

export default config;
