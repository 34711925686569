import { useGetFreeAreas } from '@hooks/keycore';
import { ModelBaseProps } from '../type';
import { Icons, Utils } from '@keypro/2nd-xp';
import { getModelIcon } from '@components';

/**
 * Free area model props.
 */
export interface FreeAreaModelProps extends ModelBaseProps {
    id?: string;
}

/**
 * Free area model.
 */
export function FreeAreaModel(props: Readonly<FreeAreaModelProps>) {
    const { children, id } = props;

    const freeArea = useGetFreeAreas(
        {
            filter: {
                id: { eq: id },
            },
        },
        {
            enabled: !!id,
        },
    );

    const freeAreaData = freeArea.data?.[0];

    return (
        <>
            {children({
                isReady: !freeArea.isLoading,
                title: freeAreaData?.name ?? '',
                tagText: freeAreaData?.type.txt ?? '',
                iconObject: getModelIcon('freearea'),
                info: [
                    {
                        infoIcon: freeAreaData?.area_size ? <Icons.Ha /> : null,
                        infoText: freeAreaData?.area_size
                            ? Utils.formatArea(freeAreaData.area_size)
                            : null,
                        id: 1,
                    },
                    {
                        infoIcon: freeAreaData?.perimeter ? (
                            <Icons.Rectangle />
                        ) : null,
                        infoText: freeAreaData?.perimeter
                            ? Utils.formatLength(freeAreaData.perimeter)
                            : null,
                        id: 2,
                    },
                ],
                more: <Icons.More />,
                zoom: <Icons.ZoomIn />,
            })}
        </>
    );
}
