import { ModelBaseProps } from '../type';
import { Icons } from '@keypro/2nd-xp';
import { useGetTelecomPremises } from '@hooks/keycom';
import { getModelIcon } from '@components';

/**
 * Telecom Premise model props.
 */
export interface TelecomPremiseModelProps extends ModelBaseProps {
    id?: string;
}

/**
 * Telecom Premise model.
 */
export function TelecomPremiseModel(props: Readonly<TelecomPremiseModelProps>) {
    const { children, id } = props;

    const telecomPremise = useGetTelecomPremises(
        {
            id: id,
        },
        {
            enabled: !!id,
        },
    );

    const telecomPremiseData = telecomPremise.data?.[0];

    return (
        <>
            {children({
                isReady: !telecomPremise.isLoading,
                title: 'Telecom Premise',
                tagText: telecomPremiseData?.type?.manufacturer ?? '',
                iconObject: getModelIcon('telecompremise'),
                more: <Icons.More />,
                zoom: <Icons.ZoomIn />,
            })}
        </>
    );
}
