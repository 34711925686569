import { FreeAreasFilter } from '@generated';
import { useQuery } from '@tanstack/react-query';
import { getFreeAreas } from 'src/apis/keycore';

export interface UseGetFreeAreasOptions {
    filter?: FreeAreasFilter;
}

export interface UseGetFreeAreasConfig {
    enabled?: boolean;
}

/**
 * Free area hook to use react-query.
 */
export function useGetFreeAreas(
    options: UseGetFreeAreasOptions,
    { enabled = true }: UseGetFreeAreasConfig = {},
) {
    const { id } = options.filter || {};

    return useQuery({
        queryKey: ['map', 'freeAreas', { id }],
        queryFn: async () => {
            return getFreeAreas({
                id,
            });
        },
        enabled,
    });
}
