import {
    Button,
    Icons,
    MapContext,
    SideMenu,
    styled,
    TabSelector,
    LoadingMask,
    Coordinates,
    PositionOverlay,
} from '@keypro/2nd-xp';
import { ActionsContainer } from './InfoObjectActions';
import { useContext, useEffect, useState } from 'react';
import { AdditionalInfo } from './AdditionalInfo';
import { useRightMenu } from '@stores';
import { t } from 'i18next';
import { FormBuilder, getData } from '@components';
import {
    FormData,
    getTranslatedTitle,
    getTypeByModel,
    isModelSupported,
} from '@form-configs';

/**
 * The props of InfoObjectContainer component.
 */
export interface InfoObjectContainerProps {
    /** Info object model name */
    model: string;
    /** Info object id */
    id: string;
}

/**
 * The InfoObjectContainer component displays object container.
 * @param model The model of the object.
 * @returns
 */
export const InfoObjectContainer = (props: InfoObjectContainerProps) => {
    const { model, id } = props;
    const mapController = useContext(MapContext)!;
    const { setMenuContent } = useRightMenu();
    const [isActionBtnOpen, setIsActionBtnOpen] = useState(false);
    const [data, setData] = useState<FormData | undefined>(undefined);
    const [highlightPosition, setHighlightPosition] = useState<number[]>([
        0, 0,
    ]);
    const [isHighlighted, setIsHighlighted] = useState(false);

    let type = null;
    let title;

    if (isModelSupported(model)) {
        type = getTypeByModel(model);
        title = <>{getTranslatedTitle(type)}</>;
    } else {
        console.error(`Unsupported model: ${model}`);
        title = <ErrorTitle>{t('unsupportedObjectType')}</ErrorTitle>;
    }

    useEffect(() => {
        if (type) {
            setIsHighlighted(false);
            setData(undefined);

            getData(type, { id: { eq: id } }).then((results) => {
                if (!results?.length) {
                    throw new Error(`No ${type} object found with id ${id}`);
                } else if (results.length > 1) {
                    throw new Error(
                        `Multiple ${type} objects found with id ${id}`,
                    );
                }

                setData(results[0]);
            });
        }
    }, [type, id]);

    const locate = () => {
        if (!data?.location) return;

        const locationParts = (data.location as string).split(';');
        const geom = mapController.wktToGeometry(
            locationParts[locationParts.length - 1],
        );

        if (geom) {
            const extent = geom.getExtent();
            const center = [
                (extent[0] + extent[2]) / 2,
                (extent[1] + extent[3]) / 2,
            ];
            mapController.pan(center);
            setIsHighlighted(true);
            setHighlightPosition(center);
        }
    };

    return (
        <StyledInfoObjectContainer>
            <InfoObjectHeader>
                <InfoObjectHeaderTitle>{title}</InfoObjectHeaderTitle>
                {type && (
                    <InfoObjectHeaderAdditionalInfo>
                        <Icons.About />
                        {data && (
                            <AdditionalInfo
                                databaseId={data.id as string}
                                createdByName={
                                    (data.createdBy ??
                                        data.created_by) as string
                                }
                                createdByEmail={
                                    (data.createdBy ??
                                        data.created_by) as string
                                }
                                createdDate={
                                    (data.createdTs ??
                                        data.created_ts ??
                                        data.created_date) as string
                                }
                                updatedByName={
                                    (data.updatedBy ??
                                        data.updated_by) as string
                                }
                                updatedByEmail={
                                    (data.updatedBy ??
                                        data.updated_by) as string
                                }
                                updatedDate={
                                    (data.updatedTs ??
                                        data.updated_ts ??
                                        data.updated_date) as string
                                }
                            />
                        )}
                    </InfoObjectHeaderAdditionalInfo>
                )}
                <InfoObjectHeaderButton
                    data-testid="info-object-right-menu-close-button"
                    kind="ghost"
                    onClick={() =>
                        setMenuContent(`InfoObject-${model}-${id}`, <></>)
                    }
                >
                    <Icons.Cross />
                </InfoObjectHeaderButton>
            </InfoObjectHeader>
            <InfoObjectContent>
                <InfoObjectActions>
                    <LocateButton
                        kind="secondary"
                        onClick={locate}
                        data-tooltip={t('locateAndHighlight')}
                    >
                        <Icons.Locate2 />
                    </LocateButton>
                    <StyledInfoObjectActionsSaveEditBtn
                        label={t('saveEdits')}
                        kind="primary"
                    />
                    <StyledInfoObjectActionsMoreBtn>
                        <Button
                            iconPosition="right"
                            label={t('actions')}
                            kind="secondary"
                            onClick={() => setIsActionBtnOpen(!isActionBtnOpen)}
                        >
                            <Icons.Arrow />
                        </Button>
                        <ActionsContainer isOpen={isActionBtnOpen} />
                    </StyledInfoObjectActionsMoreBtn>
                </InfoObjectActions>
                <InfoObjectTabs>
                    <InfoObjectTabSelector
                        options={[
                            { id: '1', label: t('information') },
                            { id: '2', label: t('attachments') },
                        ]}
                        selected="1"
                    />
                </InfoObjectTabs>
                {type && data && (
                    <FormBuilder
                        data-testid={`form-${model}`}
                        gqlType={type}
                        data={data}
                    />
                )}
            </InfoObjectContent>
            <PositionOverlay
                position={highlightPosition as Coordinates}
                positioning="bottom-center"
                style={{
                    display: isHighlighted ? 'block' : 'none',
                }}
            >
                <Icons.LocationMark style={{ width: 40, height: 40 }} />
            </PositionOverlay>
            {type && !data && <LoadingMask />}
        </StyledInfoObjectContainer>
    );
};

const ErrorTitle = styled.span`
    color: ${(props) => props.theme.colors.accents.red[10]};
`;

const LocateButton = styled(Button)`
    color: ${(props) => props.theme.colors.neutral['90']};
}`;

const StyledInfoObjectContainer = styled(SideMenu)`
    & > div:first-child {
        padding: 12px;
    }
    position: absolute;
    right: 0;
    overflow: visible;
`;

const InfoObjectHeader = styled.div`
    display: flex;
`;

const InfoObjectHeaderAdditionalInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 6px;
    position: relative;
    cursor: pointer;
    z-index: 2;
    & > svg {
        width: 16px;
        height: 16px;
        color: ${(props) => props.theme.colors.neutral['90']};
    }
    &:hover {
        & > div {
            display: block;
        }
    }
`;

const InfoObjectHeaderTitle = styled.div`
    padding-left: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
    ${(props) => props.theme.fonts['18px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const InfoObjectHeaderButton = styled(Button)`
    margin-left: auto;
    padding: 0;
    width: 32px;

    & > svg {
        width: 16px;
        height: 16px;

        path {
            stroke: ${(props) => props.theme.colors.neutral[90]};
            transform: scale(1.14);
            transform-origin: center;
        }
    }
`;

const InfoObjectContent = styled.div`
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const InfoObjectActions = styled.div`
    padding: 8px;
    display: flex;
    gap: 8px;
`;

const StyledInfoObjectActionsSaveEditBtn = styled(Button)`
    flex-grow: 1;
    ${(props) => props.theme.fonts['14px Medium']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledInfoObjectActionsMoreBtn = styled.div`
    position: relative;
    & > button {
        width: 92px;
        ${(props) => props.theme.fonts['14px Medium']};
        color: ${(props) => props.theme.colors.neutral['90']};
    }
`;

const InfoObjectTabs = styled.div`
    padding: 0 12px 12px 8px;
`;

const InfoObjectTabSelector = styled(TabSelector)`
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    & > button {
        color: ${(props) => props.theme.colors.neutral['90']};
        ${(props) => props.theme.fonts['13px Bold']};
    }
`;
