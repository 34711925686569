import { Apartment } from '@generated';
import { FormConfig } from '../types';
import { getAddresses } from '@apis/keycore';
import { joinNonEmpty } from '@components';
import { dataSourceGroup } from '../groups';

const apartmentFormatter = (value: unknown) => {
    const apartment = value as Apartment;

    return joinNonEmpty(
        [
            apartment?.apartmentLetter,
            apartment?.apartmentNumber,
            apartment?.apartmentDivision,
        ],
        ' ',
    );
};

const config: FormConfig = {
    gqlType: 'Address',
    model: 'address',
    modelAliases: ['addressnew', 'street'],
    groups: [
        {
            name: 'objectData',
            fields: [
                'streetname',
                'streetnameAlt',
                'streetno',
                'buildingName',
                {
                    name: 'apartments',
                    labelFormatter: apartmentFormatter,
                    component: 'objectReference',
                },
                'district',
                'subdivision',
                'city',
                'cityAlt',
                'zipcode',
                'province',
                {
                    name: 'usagePurpose',
                    filter: {
                        groupname: { eq: 'BUILDING_USAGE' },
                    },
                },
                {
                    name: 'usagePurposeAlt',
                    filter: {
                        groupname: { eq: 'KN_ADDR_SPEC' },
                    },
                },
                'buildingId',
                'propertyId',
                'municipality',
            ],
        },
        dataSourceGroup,
    ],
    functions: {
        get: getAddresses,
    },
    queryCollection: 'addresses',
};

export default config;
