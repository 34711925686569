import { useQuery } from '@tanstack/react-query';
import { getManhole } from 'src/apis/keycom';

export interface UseGetManholesOptions {
    id?: string;
}

export interface UseGetManholesConfig {
    enabled?: boolean;
}

/**
 * Manhole hook to use react-query.
 */
export function useGetManholes(
    options: UseGetManholesOptions,
    { enabled = true }: UseGetManholesConfig = {},
) {
    const { id } = options;

    return useQuery({
        queryKey: ['map', 'manholes', { id }],
        queryFn: async () => {
            return getManhole(id!);
        },
        enabled,
    });
}
