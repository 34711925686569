import { GetFreeAreasQuery, FreeAreasFilter, FreeArea } from '@generated';
import GetFreeAreas from './queries/GetFreeAreas.graphql';
import { sendGraphQLRequest } from '@utils';

/**
 * Gets the free areas.
 * @returns The free areas.
 * @throws {Error} If the request fails.
 */
export const getFreeAreas = async (filter?: FreeAreasFilter) => {
    try {
        const response = await sendGraphQLRequest<GetFreeAreasQuery>(
            GetFreeAreas,
            { filter: filter },
        );

        if (!response.data.getFreeAreas) {
            throw Error('No data returned');
        }

        return response.data.getFreeAreas.freeAreas?.filter(
            Boolean,
        ) as FreeArea[];
    } catch (error) {
        throw Error('Failed to get free areas: ' + error);
    }
};
