import { useQuery } from '@tanstack/react-query';
import { getPoles } from 'src/apis/keycom';

export interface UseGetPolesOptions {
    id?: string;
}

export interface UseGetPolesConfig {
    enabled?: boolean;
}

/**
 * Pole hook to use react-query.
 */
export function useGetPoles(
    options: UseGetPolesOptions,
    { enabled = true }: UseGetPolesConfig = {},
) {
    const { id } = options;

    return useQuery({
        queryKey: ['map', 'poles', { id }],
        queryFn: async () => {
            return getPoles({
                id: { eq: id },
            });
        },
        enabled,
    });
}
