import { InfoObject } from '@components/InfoObject';
import { getModelIcon, IconModel } from '@components/utils';
import { InfoTool } from '@generated';
import {
    ToggleObject,
    ToggleObjectItem,
    ToggleObjectProps,
} from '@keypro/2nd-xp';
import { useRightMenu } from '@stores';

const definedTitle = (model: string) => {
    switch (model) {
        case 'freearea':
            return 'FREE AREAS';
        case 'plan':
            return 'PLANS';
        case 'splice':
            return 'SPLICES';
        case 'manhole':
            return 'MANHOLES';
        case 'point':
            return 'POINTS';
        case 'telecompremise':
            return 'Telecom Premises';
        case 'conduit':
            return 'Conduits';
        case 'cable':
            return 'Cables';
        case 'freeline':
            return 'Lines';
        case 'externaldoc':
            return 'Documents';
        case 'pole':
            return 'Poles';
        case 'telecomarea':
            return 'Telecom Areas';
        case 'servicearea':
            return 'Service Areas';
    }
};

/**
 * The ToggleObjectController component props.
 */
export interface ToggleObjectControllerProps extends ToggleObjectProps {
    model: string;
    value?: InfoTool[];
}

/**
 * The ToggleObjectController component displays object nearby of the map.
 * @returns The ToggleObjectController component
 */
export const ToggleObjectController = ({
    model,
    shouldExpandable,
    isExpandedByDefault,
    value,
}: ToggleObjectControllerProps) => {
    const { setMenuContent } = useRightMenu();

    return (
        <ToggleObject
            title={definedTitle(model)}
            shouldExpandable={shouldExpandable}
            isExpandedByDefault={isExpandedByDefault}
            quantity={value?.length}
        >
            {value?.map((result: InfoTool) => {
                return (
                    <ToggleObjectItem
                        icon={getModelIcon(result.model as IconModel)}
                        text={[result.model, result.pk]
                            .filter(Boolean)
                            .join(' ')}
                        key={result.pk}
                        onClickAction={() => {
                            setMenuContent(
                                `InfoObject-${result.model}-${result.pk}`,
                                <InfoObject
                                    model={result.model!}
                                    id={result.pk!}
                                />,
                            );
                        }}
                    />
                );
            })}
        </ToggleObject>
    );
};
