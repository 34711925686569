import { useQuery } from '@tanstack/react-query';
import { getTelecomAreas } from 'src/apis/keycom';

export interface UseGetTelecomAreasOptions {
    id?: string;
}

export interface UseGetTelecomAreasConfig {
    enabled?: boolean;
}

/**
 * Telecom Area hook to use react-query.
 */
export function useGetTelecomAreas(
    options: UseGetTelecomAreasOptions,
    { enabled = true }: UseGetTelecomAreasConfig = {},
) {
    const { id } = options;

    return useQuery({
        queryKey: ['map', 'telecomAreas', { id }],
        queryFn: async () => {
            return getTelecomAreas({
                id: {
                    eq: options.id,
                },
            });
        },
        enabled,
    });
}
