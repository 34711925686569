import { useQuery } from '@tanstack/react-query';
import { getPoints } from 'src/apis/keycore';

export interface UseGetPointsOptions {
    id?: string;
}

export interface UseGetPointsConfig {
    enabled?: boolean;
}

/**
 * Point hook to use react-query.
 */
export function useGetPoints(
    options: UseGetPointsOptions,
    { enabled = true }: UseGetPointsConfig = {},
) {
    const { id } = options;

    return useQuery({
        queryKey: ['map', 'points', { id }],
        queryFn: async () => {
            return getPoints({
                id: { eq: id },
            });
        },
        enabled,
    });
}
