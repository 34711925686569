import { GetSplicesQuery, Splice } from '@generated';
import GetSplices from './queries/GetSplices.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter } from '@apis/utils';

/**
 * Gets the splices.
 * @param filter The filter.
 * @returns The splices.
 * @throws {Error} If the request fails.
 */
export const getSplices = async (filter: GraphQLFilter) => {
    try {
        const response = await sendGraphQLRequest<GetSplicesQuery>(GetSplices, {
            filter: filter,
        });

        if (!response.data.getSplices) {
            throw Error('No data returned');
        }

        return response.data.getSplices.splices?.filter(Boolean) as Splice[];
    } catch (error) {
        throw Error('Failed to get splices: ' + error);
    }
};
