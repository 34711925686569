import { useQuery } from '@tanstack/react-query';
import { getConduits } from 'src/apis/keycom';

export interface UseGetConduitsOptions {
    id?: string;
}

export interface UseGetConduitsConfig {
    enabled?: boolean;
}

/**
 * Conduit hook to use react-query.
 */
export function useGetConduits(
    options: UseGetConduitsOptions,
    { enabled = true }: UseGetConduitsConfig = {},
) {
    const { id } = options;

    return useQuery({
        queryKey: ['map', 'conduits', { id }],
        queryFn: async () => {
            return getConduits({
                id: { eq: options.id },
            });
        },
        enabled,
    });
}
