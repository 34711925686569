import { FreeLineFilter } from '@generated';
import { useQuery } from '@tanstack/react-query';
import { getFreeLines } from 'src/apis/keycore';

export interface UseGetFreeLinesOptions {
    filter?: FreeLineFilter;
}

export interface UseGetFreeLinesConfig {
    enabled?: boolean;
}

/**
 * FreeLine hook to use react-query.
 */
export function useGetFreeLines(
    options: UseGetFreeLinesOptions,
    { enabled = true }: UseGetFreeLinesConfig = {},
) {
    const { id } = options.filter || {};

    return useQuery({
        queryKey: ['map', 'freeLines', { id }],
        queryFn: async () => {
            return getFreeLines({
                id,
            });
        },
        enabled,
    });
}
