import { Dispatch, RefObject, SetStateAction } from 'react';
import { Icons, SelectOptionItem } from '@keypro/2nd-xp';

/**
 * Adds a click event listener to the document that deactivates the component
 * when clicking outside of it. Should be used in a useEffect hook.
 * @param ref The reference to the component
 * @param setActive The setter for the active state
 * @param isActive The active state
 * @returns The cleanup function for useEffect
 */
export const handleOutsideClick = (
    ref: RefObject<HTMLElement>,
    setActive: Dispatch<SetStateAction<boolean>>,
    isActive: boolean,
) => {
    const onClick = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setActive(false);
        }
    };

    if (isActive) {
        document.addEventListener('click', onClick);
    } else {
        document.removeEventListener('click', onClick);
    }

    return () => {
        document.removeEventListener('click', onClick);
    };
};

const modelIcons = {
    freearea: <Icons.Area />,
    servicearea: <Icons.Area />,
    telecomarea: <Icons.Area />,
    plan: <Icons.Area />,
    splice: <Icons.Splice />,
    manhole: <Icons.Manhole />,
    point: <Icons.AddressPoint />,
    addressnew: <Icons.AddressPoint />,
    street: <Icons.AddressPoint />,
    conduit: <Icons.Fiber />,
    cable: <Icons.Cable />,
    freeline: <Icons.Duct />,
    document: <Icons.Fat />,
    pole: <Icons.Fat />,
    telecompremise: <Icons.Fat />,
    default: <Icons.Fat />,
};

export type IconModel = keyof typeof modelIcons;

export const modelNames = {
    freearea: 'Areas',
    servicearea: 'Areas',
    telecomarea: 'Areas',
    plan: 'Plans',
    splice: 'Splices',
    manhole: 'Manholes',
    point: 'Addresses',
    addressnew: 'Addresses',
    street: 'Addresses',
    conduit: 'Conduits',
    cable: 'Cables',
    freeline: 'Ducts',
    document: 'Documents',
    pole: 'Poles',
    telecompremise: 'Premises',
    default: 'Premises',
};

export type ModelName = keyof typeof modelNames;

// Predefined object types for the select dropdown
export const objectTypes: SelectOptionItem[] = [
    {
        value: 'areasearch',
        label: 'Areas',
    },
    {
        value: 'addresssearch',
        label: 'Addresses',
    },
    {
        value: 'premisesearch',
        label: 'Premises',
    },
    {
        value: 'cablesearch',
        label: 'Cables',
    },
    {
        value: 'splicesearch',
        label: 'Splices',
    },
    {
        value: 'ductsearch',
        label: 'Ducts',
    },
    {
        value: 'manholesearch',
        label: 'Manholes',
    },
    {
        value: 'conduitsearch',
        label: 'Conduits',
    },
];

/**
 * Get icon for the model.
 * @param model The model name.
 * @returns The icon component.
 */
export const getModelIcon = (model: IconModel) => {
    if (!modelIcons[model]) {
        return modelIcons.default;
    }

    return modelIcons[model];
};
