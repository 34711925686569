import { useQuery } from '@tanstack/react-query';
import { getSplices } from 'src/apis/keycom';

export interface UseGetSplicesOptions {
    id?: string;
}

export interface UseGetSplicesConfig {
    enabled?: boolean;
}

/**
 * Splice hook to use react-query.
 */
export function useGetSplices(
    options: UseGetSplicesOptions,
    { enabled = true }: UseGetSplicesConfig = {},
) {
    const { id } = options;

    return useQuery({
        queryKey: ['map', 'splices', { id }],
        queryFn: async () => {
            return getSplices({
                id: { eq: options.id },
            });
        },
        enabled,
    });
}
