import { ModelBaseProps } from '../type';
import { Icons } from '@keypro/2nd-xp';
import { useGetSplices } from '@hooks/keycom';
import { getModelIcon } from '@components';

/**
 * Splice model props.
 */
export interface SpliceModelProps extends ModelBaseProps {
    id?: string;
}

/**
 * Splice model.
 */
export function SpliceModel(props: Readonly<SpliceModelProps>) {
    const { children, id } = props;

    const splice = useGetSplices(
        {
            id: id,
        },
        {
            enabled: !!id,
        },
    );

    const spliceData = splice.data?.[0];

    return (
        <>
            {children({
                isReady: !splice.isLoading,
                title: 'Splice',
                tagText: spliceData?.type?.txt ?? '',
                iconObject: getModelIcon('splice'),
                more: <Icons.More />,
                zoom: <Icons.ZoomIn />,
            })}
        </>
    );
}
