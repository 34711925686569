import { ModelBaseProps } from '../type';
import { Icons } from '@keypro/2nd-xp';
import { useGetPoints } from '@hooks/keycore';

/**
 * Point model props.
 */
export interface PointModelProps extends ModelBaseProps {
    id?: string;
}

/**
 * Point model.
 */
export function PointModel(props: Readonly<PointModelProps>) {
    const { children, id } = props;

    const point = useGetPoints(
        {
            id: id,
        },
        {
            enabled: !!id,
        },
    );

    const pointData = point.data?.[0];

    return (
        <>
            {children({
                isReady: !point.isLoading,
                title: 'Point',
                tagText: pointData?.identification ?? '',
                iconObject: <Icons.LocatePoint />,
                more: <Icons.More />,
                zoom: <Icons.ZoomIn />,
            })}
        </>
    );
}
