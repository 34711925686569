import { getConduits } from '@apis/keycom';
import { FormConfig } from '../types';
import { altDataSourceGroup, planGroup, telecomAreaGroup } from '../groups';

const config: FormConfig = {
    gqlType: 'Conduit',
    model: 'conduit',
    groups: [
        {
            name: 'objectData',
            fields: [
                'identification',
                {
                    name: 'usageState',
                    filter: {
                        groupname: { eq: 'CONDUIT_STATE' },
                    },
                },
                'length',
                {
                    name: 'conduitType',
                    filter: {
                        groupname: { eq: 'CONDUIT_TYPE' },
                    },
                },
                {
                    name: 'measure',
                    filter: {
                        groupname: { eq: 'CABLE_MEASURE' },
                    },
                },
                {
                    name: 'profileStatus',
                    filter: {
                        groupname: { eq: 'PROFILE_STATUS' },
                    },
                },
                {
                    name: 'surfaceType',
                    filter: {
                        groupname: { eq: 'CONDUIT_SURFACE_TYPE' },
                    },
                },
                { name: 'remarks', component: 'textarea' },
            ],
        },
        telecomAreaGroup,
        planGroup,
        {
            name: 'common',
            fields: [
                {
                    name: 'locationAccuracy',
                    filter: {
                        groupname: { eq: 'LOCATION_ACCURACY' },
                    },
                },
                {
                    name: 'heightAccuracy',
                    filter: {
                        groupname: { eq: 'H_ACCURACY' },
                    },
                },
                {
                    name: 'mappingMethod',
                    filter: {
                        groupname: { eq: 'MAPPING_METHOD' },
                    },
                },
                'surveyAmount',
                {
                    name: 'safetyDistance',
                    filter: {
                        groupname: { eq: 'SAFETY_DISTANCE' },
                    },
                },
                'mapper',
            ],
        },
        altDataSourceGroup,
    ],
    functions: {
        get: getConduits,
    },
    queryCollection: 'conduits',
};

export default config;
